div.container {
	flex: auto;
	display: flex;
	flex-direction: column;
	position: relative;
}

div.container > div.header {
	flex: 0 0 65px;
	display: flex;
	align-items: center;
	padding: 0 1rem;
}

div.container > div.main {
	flex: auto;
	display: flex;
}

div.container > div.footer {
	flex: 0 0 60px;
	display: flex;
	align-items: center;
	justify-content: end;
	padding: 0 1rem;
}

div.footer > div:first-child > span:first-child {
	font-family: 'Changa', sans-serif;
	font-weight: 600;
	color: #D3D3D3;
	font-size: 24px;
	font-weight: 600;
}

div.footer > div:first-child > span:last-child {
	font-family: 'Changa One', cursive;
	color: #D3D3D3;
	font-size: 24px;
}

div.container > div.main.ended {
	justify-content: center;
	align-items: center;
}

div.main.ended > h1.heading {
	font-weight: 700;
	color: #76767675;
}

div.main > div.leftPane {
	flex: 0 0 45%;
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

div.leftPane p.preText,
div.main.ended p.preText {
	margin-bottom: 0;
	color: #767676;
	font-weight: 700;
}

div.leftPane h1.heading,
div.main.ended h1.heading {
	margin-bottom: 0.3rem;
	color: var(--primary-color);
	font-weight: 600;
}

div.leftPane p.subheading,
div.main.ended p.subheading {
	margin-bottom: 0;
	color: #767676;
	font-weight: 700;
	font-size: 16px;
}

div.main > div.rightPane {
	flex: 0 0 55%;
	padding: 2rem;
	display: flex;
	align-items: center;
}

div.titleContainer > h1 {
	font-weight: 700;
	color: #b1b1b1;
}

div.rightPane > div:first-child {
	margin-left: 10rem;
}

div.rightPane div.participantsContainer {
	margin-bottom: 1rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

div.participantsContainer > div.avatarsContainer {
	display: flex;
	margin-right: 0.5rem;
	position: relative;
}

div.avatarsContainer > div.avatar-0 {
	position: relative;
	left: 0px;
}

div.avatarsContainer > div.avatar-1 {
	position: relative;
	left: -8px;
}

div.avatarsContainer > div.avatar-2 {
	position: relative;
	left: -16px;
}

div.avatarsContainer > div.avatar-3 {
	position: relative;
	left: -24px;
}

div.participantsContainer > span {
	font-size: 17px;
	color: #4A4A4A;
	font-weight: 600;
}

div.rightPane div.actionsContainer {}

div.actionsContainer > button {
	height: unset;
	font-weight: 700;
	padding: 8px 40px;
	border-radius: 25px;
}