@font-face {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changa/v17/2-c79JNi2YuVOUcOarRPgnNGooxCZy2xQjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changa/v17/2-c79JNi2YuVOUcOarRPgnNGooxCZ_OxQjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changa/v17/2-c79JNi2YuVOUcOarRPgnNGooxCZ62xQjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changa/v17/2-c79JNi2YuVOUcOarRPgnNGooxCZ5-xQjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changa/v17/2-c79JNi2YuVOUcOarRPgnNGooxCZ3O2QjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changa/v17/2-c79JNi2YuVOUcOarRPgnNGooxCZ0q2QjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changa/v17/2-c79JNi2YuVOUcOarRPgnNGooxCZy22QjA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa One';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changaone/v16/xfu20W3wXn3QLUJXhzq42ATivA.ttf) format('truetype');
}
@font-face {
  font-family: 'Changa One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/changaone/v16/xfu00W3wXn3QLUJXhzq46AY.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hind/v15/5aU19_a8oxmIfMJaIRs.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hind/v15/5aU69_a8oxmIRG4.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hind/v15/5aU19_a8oxmIfJpbIRs.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hind/v15/5aU19_a8oxmIfLZcIRs.ttf) format('truetype');
}
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/hind/v15/5aU19_a8oxmIfNJdIRs.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSqeOvHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSd-OvHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSKeOvHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSG-OvHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsS9-SvHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSzuSvHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSqeSvHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSgOSvHg.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexRNRwaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexc1RwaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNRwaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexaFRwaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexU1WwaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexXRWwaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexRNWwaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexTpWwaA.ttf) format('truetype');
}
