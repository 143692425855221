div.logoContainer {
	display: flex;
}

div.logoContainer > svg {
	height: 40px;
}

div.title {
	margin-left: 1rem;
}

div.title > span {
	font-weight: 500;
	font-size: 16px;
	color: #333;
}

div.liveLogo {
	display: flex;
	margin-left: 1.5rem;
	align-items: center;
	justify-content: center;
}

div.liveLogo > div.greenDot {
	height: 13px;
	width: 13px;
	border-radius: 100%;
	background-color: #46B72B;
}

div.liveLogo > span {
	color: #767676;
	font-weight: 800;
	font-size: 12px;
	margin-left: 0.25rem;
	position: relative;
	top: 1px;
}