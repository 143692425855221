.rate-radio-btns {
  /* width: 50%; */
  margin-bottom: 40px;
}
.rate-btns {
  margin-top: 20px;
  display: inline-block;
  width: 20%;
}
.rate-rad-btns{
  font-size: 15px;
}
.rate-rad-btns input {
  display: none;
}
.rate-rad-btns span {
  padding: 10px;
  background-color: #f5d7c8;
  /* border: 1px solid #b83227; */
  width: 130px;
  
  position: absolute;
  overflow: hidden;
  line-height: 1;
  text-align: center;
  border-radius: 20px;
}
.rate-rad-btns input:checked + span {
  background-color: #de6834;
  color: white;
}
