div.container {
	flex: auto;
	display: flex;
	flex-direction: column;
	position: relative;
}

div.container > div.header {
	flex: 0 0 65px;
	display: flex;
	align-items: center;
	padding: 0 1rem;
}

div.container > div.main {
	flex: auto;
	display: flex;
	border-top: 3px solid var(--primary-color);
	flex-direction: column;
	position: relative;
}

div.main > div.topPane {
	flex: 0 0 200px;
	background-color: #F8F3F0;
	padding: 0 20%;
	display: flex;
	justify-content: start;
	align-items: center;
	position: relative;
}

div.topPane h1.heading {
	color: var(--primary-color);
	font-weight: 700;
}

div.topPane p.subheading {
	margin-bottom: 0;
	color: #767676;
	font-weight: 600;
	font-size: 17px;
}

div.topPane div.logo {
	position: relative;
	left: -50px;
}

div.logo > img {
	height: 60px;
}

div.main > div.bottomPane {
	flex: auto;
	padding: 1.5rem 23%;
	display: flex;
	position: absolute;
	top: 200px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	overflow-y: auto;
}

div.bottomPane > form {
	flex: auto;
}

div.bottomPane > form > div.questionContainer {
	border-bottom: 2px solid #C1C1C140;
	padding: 1rem 0;
}

div.questionContainer > p.question {
	font-size: 18px;
	font-weight: 500;
}

div.bottomPane > form div.rangeInputContainer {
	display: flex; 
	align-items: center;
	width: 50%;
}

div.rangeInputContainer > p {
	font-size: 18px;
	position: relative;
	top: 8px;
	font-weight: 600;
}

div.rangeInputContainer > p.startRange {
	margin-right: 1rem;
}

div.rangeInputContainer > p.endRange {
	margin-left: 1rem;
}

div.rangeInputContainer > div.rangeInputWrapper {
	position: relative;
	flex: auto;
}

div.rangeInputWrapper > input.rangeInput {
	-webkit-appearance: none;
	width: 100%;
	outline: none;
}

input.rangeInput::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-color: var(--primary-color);
  border-radius: 5px;
	opacity: 0.7;
}

input.rangeInput::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 5px;
	opacity: 0.7;
}

input.rangeInput::-webkit-slider-thumb {
  height: 16px;
  width: 12px;
  border-radius: 5px;
  background-color: var(--primary-color);
  cursor: pointer;
  -webkit-appearance: none;
	position: relative;
	top: -4px;
}

input.rangeInput::-moz-range-thumb {
  height: 16px;
  width: 12px;
  border-radius: 5px;
  background-color: var(--primary-color);
  cursor: pointer;
	top: -4px;
}

div.rangeInputWrapper > div.rangeBadge{
	z-index: 1;
	pointer-events: none;
	position: absolute;
	top: -12px;
	background-color: var(--primary-color);
	color: #fff;
	width: 28px;
	height: 32px;
	border-radius: 5px;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
}
	
div.bottomPane > form textarea.description {
	background-color: #F7F7F7;
	border: 1px solid #F7F7F7;
	font-style: italic;
	min-width: 50%;
	min-height: 75px;
	padding: 10px;
	font-size: 16px;
}

div.bottomPane > form > div.submitButtonContainer {
	display: flex;
	justify-content: end;
	margin-top: 2rem;
}

div.submitButtonContainer > button {
	height: unset;
	font-weight: 700;
	padding: 8px 40px;
	border-radius: 25px;
	font-size: 16px;
	margin-bottom: 1rem;
}