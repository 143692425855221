@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@primary-color: #de6834;

:root {
	--primary-color: @primary-color;
}

@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Changa:wght@200;300;400;500;600;700;800&family=Changa+One:ital@0;1&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	outline: none;
}

.selectable,
.selectable *,
.chat-container * {
	-webkit-touch-callout: text;
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

html, body {
	font-family: 'Mulish', sans-serif;
	color: #333;
}

::-webkit-scrollbar {
	width: 7px;
	height: 5px;
}

::-webkit-scrollbar-track {
	border-radius: 20px;
}
 
::-webkit-scrollbar-thumb {
	background: #e5e5e5;
	border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
	background: #8e8e8e;
}

div#root {
  height: 100%;
}

div.ant-spin {
  max-height: unset !important;
}

#zmmtg-root {
  display: none;
}

div.ant-message div.ant-message-notice > div.ant-message-notice-content {
	background-color: transparent;
	box-shadow: none;
}